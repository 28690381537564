import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Button, ButtonToolbar } from 'react-bootstrap';
import * as AdminUserAction from '../../actions/adminuserActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as CONFIG from '../../utils/config';
import Alert from '../../components/Alert';
import RoleManagementService from '../../services/roleManagementService';
import Notification from "../../utils/notification";
import AdminUserService from "../../services/adminuserService";
import { personNameRegex } from '../../utils/helpers'

class EditAdminUserPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            first_name: '',
            last_name: '',
            email: '',
            contact_number: '',
            trn_number: '',
            flag: null,
            gender: '',
            name_title: '',
            date_of_birth: '',
            assigned_role: '',
            roles: '',
            status: '',
            currentStatus: ''
        };

        // this.props.fetchadminuser(id);
        this.validator = new SimpleReactValidator({
            validators: {
                birthdate: {
                    message: 'The date of birth must be less than todays date',
                    rule: (val, params) => {
                        return (new Date(val) > new Date(params) ? false : true)
                    },
                    required: true
                }
            }
        });
        this.onChange = this.onChange.bind(this);
        this.validateAndSubmit = this.validateAndSubmit.bind(this);
    }

    componentDidMount = () => {
        AdminUserService.fetchadminuser(this.props.match.params.id)
            .then((resp) => {
                let data = resp.data;
                this.setState({
                    id: data.admin_id ? data.admin_id : '',
                    admin_id: data.admin_id ? data.admin_id : '',
                    first_name: data.first_name ? data.first_name : '',
                    last_name: data.last_name ? data.last_name : '',
                    email: data.email ? data.email : '',
                    contact_number: data.contact_number ? data.contact_number : '',
                    trn_number: data.trn_number ? data.trn_number : '',
                    gender: data.gender ? data.gender : '',
                    name_title: data.name_title ? data.name_title : '',
                    date_of_birth: data.date_of_birth ? data.date_of_birth : '',
                    flag: false,
                    assigned_role: data.roles ? data.roles.role_id : '',
                    currentStatus: data.status === 1 ? 'active' : 'inactive',
                    status: data.status === 1 ? 'active' : 'inactive',
                    language: data.language ? data.language : ''
                });
            })
            .catch((error) => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    this.props.history.push('/admins');
                    Notification.show("error", errorData);
                } else {
                    this.props.history.push("/admins");
                    Notification.show('error', { message: "Something Went Wrong, Try Again" });
                }
            });
        RoleManagementService.getRoleName().then(resp => {
            this.setState({
                roles: resp.data.data
            })
        }).catch(_error => {
            console.log()
        })
    }

    onChange(e) {
        const name = e.target.name
        const value = e.target.value

        if (name === 'name_title') {
            this.setState({
                name_title: value
            })
        } else {
            this.setState({ [name]: value })
        }

    }

    validateAndSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.adminuserupdate(this.state, this.props.history);
    }


    render() {
        return <div>
            <Helmet>
                <title>Edit Admin User</title>
            </Helmet>
            <Content title="Edit Admin User">
                <Box>
                    <Box.Head title="Edit Admin User Form"></Box.Head>
                    <Box.Body>
                        <Alert />
                        {
                            this.state.id &&
                            <form onSubmit={this.validateAndSubmit}>

                                <FormGroup className="row" controlId="title">
                                    <Col sm={2}>
                                        <ControlLabel>Select Name Title: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="name_title" componentClass="select" value={this.state.name_title} onChange={this.onChange}>
                                            <option value=''>Select Title</option>
                                            {CONFIG.NAMETITLE.map((value) => {
                                                return <option value={value}>{value}</option>
                                            })}
                                        </FormControl>
                                        {this.validator.message('name_title', this.state.name_title, 'required')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>First Name:  <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='first_name'
                                            type="text"
                                            value={this.state.first_name}
                                            placeholder="Enter your first name"
                                            onChange={this.onChange}
                                            autoComplete='off'
                                        />
                                        {this.validator.message('first_name', this.state.first_name, ['required', 'string', { regex: personNameRegex() }])}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Last Name: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='last_name'
                                            type="text"
                                            value={this.state.last_name}
                                            placeholder="Enter your last name"
                                            onChange={this.onChange}
                                        />
                                        {this.validator.message('last_name', this.state.last_name, ['required', 'string', { regex: personNameRegex() }])}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Email: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='email'
                                            type="email"
                                            value={this.state.email}
                                            placeholder="Enter your email"
                                            onChange={this.onChange}
                                        />
                                        {this.validator.message('email', this.state.email, 'required')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Contact Number: </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='contact_number'
                                            type="number"
                                            value={this.state.contact_number}
                                            placeholder="Enter your contact number"
                                            onChange={this.onChange}
                                        />
                                        {this.validator.message('contact_number', this.state.contact_number, 'phone')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Gender: </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='gender'
                                            type="text"
                                            value={this.state.gender}
                                            placeholder={this.state.gender}
                                            onChange={this.onChange}
                                            componentClass="select"
                                        >
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </FormControl>
                                        {this.validator.message('gender', this.state.gender, 'required')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Date Of Birth: </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='date_of_birth'
                                            type="date"
                                            value={this.state.date_of_birth}
                                            placeholder=''
                                            onChange={this.onChange}
                                            min='1800-01-01'
                                            max="2999-12-31"
                                        />
                                        {this.validator.message('date_of_birth', this.state.date_of_birth, 'birthdate:' + new Date())}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>TRN Number: *</ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='trn_number'
                                            type="text"
                                            value={this.state.trn_number}
                                            placeholder="Enter your TRN Number"
                                            onChange={this.onChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('trn_number', this.state.trn_number, 'required')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="assigned_role">
                                    <Col sm={2}>
                                        <ControlLabel>Select Role: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name="assigned_role"
                                            componentClass="select"
                                            value={this.state.assigned_role}
                                            onChange={this.onChange} >
                                            <option value="" disabled>--Select Role--</option>
                                            {this.state.roles && this.state.roles.map((role, index) => (
                                                <option value={role.role_id} key={index}>{role.display_name}</option>
                                            ))}
                                        </FormControl>
                                        {this.validator.message('role', this.state.assigned_role, 'required')}
                                    </Col>
                                </FormGroup>

                                {this.state.currentStatus === 'active' && <FormGroup className="row" controlId="assigned_role">
                                    <Col sm={2}>
                                        <ControlLabel>Select Status: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name="status"
                                            componentClass="select"
                                            value={this.state.status}
                                            onChange={this.onChange} >
                                            <option value="" disabled>--Select Status--</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </FormControl>
                                    </Col>
                                </FormGroup>}

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <ButtonToolbar>
                                            <Button type='submit' bsStyle="primary">Save</Button>
                                            <a href="/admins" className="btn btn-info">Back</a>
                                        </ButtonToolbar>
                                    </Col>
                                </FormGroup>
                            </form>
                        }
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    // admin_user: state.admin_user,
    // admin: state.auth.admin
});

const mapActionsToProps = ({
    // fetchadminuser: AdminUserAction.fetchadminuser,
    adminuserupdate: AdminUserAction.adminuserupdate
});

export default connect(mapStateToProps, mapActionsToProps)(EditAdminUserPage);