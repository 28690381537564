import SimpleReactValidator from "simple-react-validator";
import 'simple-react-validator/dist/locale/es';

// Spanish
SimpleReactValidator.addLocale('es', {
  alpha_space          : 'El :attribute solo puede contener letras y espacios.',
  alpha_num            : 'El :attribute sólo puede contener letras y números.',
  alpha_num_space      : 'El :attribute solo puede contener letras, números y espacios.',
  alpha_num_dash       : 'El :attribute solo puede contener letras, números y guiones.',
  alpha_num_dash_space : 'El :attribute solo puede contener letras, números, guiones y espacios.',

  card_exp             : 'El :attribute debe ser una fecha de caducidad válida.',
  card_num             : 'El :attribute debe ser un número de tarjeta de crédito válido.',
  currency             : 'El :attribute debe ser una moneda válida.',

  phone                : 'El :attribute debe ser un número de teléfono válido.',

  typeof               : 'El :attribute no es el tipo correcto de :type.',


  accepted             : ':attribute debe ser aceptado.',
  after                : ':attribute debe ser una fecha posterior a :date.',
  after_or_equal       : ':attribute debe ser una fecha posterior o igual a :date.',
  alpha                : ':attribute sólo debe contener letras.',

  array                : ':attribute debe ser un conjunto.',
  before               : ':attribute debe ser una fecha anterior a :date.',
  before_or_equal      : ':attribute debe ser una fecha anterior o igual a :date.',
  between              : ':attribute tiene que estar entre :min - :max:type.',
  boolean              : 'El campo :attribute debe tener un valor verdadero o falso.',

  date                 : ':attribute no es una fecha válida.',
  date_equals          : ':attribute debe ser una fecha igual a :date.',
  email                : ':attribute no es un correo válido',
  in                   : ':attribute es inválido :values.',
  integer              : ':attribute debe ser un número entero.',
  max                  : ':attribute no debe ser mayor a :max:type.',
  min                  : 'El tamaño de :attribute debe ser de al menos :min:type.',
  not_in               : ':attribute es inválido :values.',
  not_regex            : 'El formato del campo :attribute no es válido.',
  numeric              : ':attribute debe ser numérico.',

  regex                : 'El formato de :attribute es inválido.',
  required             : 'El campo :attribute es obligatorio.',
  size                 : 'El tamaño de :attribute debe ser :size:type.',
  string               : 'El campo :attribute debe ser una cadena de caracteres.',

  url                  : 'El formato :attribute es inválido.',
});