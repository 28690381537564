import _ from 'lodash';
import moment from 'moment';
import * as authUtils from './auth';
import i18n from 'i18next';

export function config(key, value = '') {
    return process.env['REACT_APP_' + key] ? process.env['REACT_APP_' + key] : value;
}

export function getApiHeader(extraHeader = {}, checkAuth = true) {
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Language': getLanguage()
    };

    if (checkAuth) {
        let accessToken = authUtils.getSessionToken();
        if (accessToken && accessToken.access_token) {
            headers.Authorization = 'Bearer ' + accessToken.access_token;
        }
    }

    return { ...headers, ...extraHeader };
}


export function apiRoute($url) {
    return config('API_URL') + $url;
}

export function createPaginateQueryString(page = 1, sizePerPage = 25, sortBy = null, searchText = '', filter = '') {
    let params = {
        page: page,
        limit: sizePerPage,
    };

    if (sortBy && sortBy.sortField) {
        let field = sortBy.sortField;
        let order = sortBy.sortOrder ? sortBy.sortOrder : 'asc';
        params.sort = field + ':' + order;
    }

    if (searchText) {
        params.search = searchText
    }

    if (filter) {
        Object.keys(filter).forEach(function (key) {
            params[key] = filter[key]
        });
    }

    return params;
}

export function fullNameStr(user) {
    if (user) {
        return user.first_name + ' ' + user.last_name;
    }

    return '';
}

export function getDateFormat(timestamp) {
    if (!timestamp) {
        return "";
    }

    return moment(timestamp).format('DD MMM YYYY');
}

export function getTimeFormat(timestamp) {
    if (!timestamp) {
        return "";
    }

    const time = new Date(timestamp);

    return time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds();
}

export function getDateTimeFormat(timestamp, format = 'DD-MM-YYYY HH:mm:ss') {
    if (!timestamp) {
        return "";
    }

    return moment(timestamp).format(format);
}

export function dateCompare(timestampA, timestampB) {
    let dateA = new Date(timestampA);
    let dateB = timestampB !== undefined ? new Date(timestampB) : new Date();

    let msDateA = Date.UTC(dateA.getFullYear(), dateA.getMonth() + 1, dateA.getDate());
    let msDateB = Date.UTC(dateB.getFullYear(), dateB.getMonth() + 1, dateB.getDate());

    if (parseFloat(msDateA) < parseFloat(msDateB))
        return -1;  // lt
    else if (parseFloat(msDateA) === parseFloat(msDateB))
        return 0;  // eq
    else if (parseFloat(msDateA) > parseFloat(msDateB))
        return 1;  // gt

    return null;  // error
}

export const hasPermission = (user, rights) => {
    if (_.isEmpty(user) || _.isEmpty(user.permissions)) {
        return false;
    }

    return rights.some(right => user.permissions.includes(right));
}

export const hasRole = (user, roles) => {
    if (_.isEmpty(user) || _.isEmpty(user.roles)) {
        return false;
    }

    return roles.some(role => user.roles.includes(role));
}

export function fullAddressStr(address) {
    if (address) {
        return address.address_line_1 + ', ' + (address.address_line_2 ? address.address_line_2 + ', ' : '') + (address.address_line_3 ? address.address_line_3 + '. ' : '') + address.city + '-' + address.pincode;
    }

    return '';
}

export function filters(filter = '') {
    let params = {

    };
    if (filter) {
        Object.keys(filter).forEach(function (key) {
            params[key] = filter[key]
        });
    }
    return params;
}

export function getCardObj(value) {
    let splitted = value.split('^', 3);

    if (splitted[0] && splitted[1] && splitted[2]) {
        return {
            user_code: splitted[0],
            card_number: splitted[1],
            hash: splitted[2],
        };
    }

    return false;
}

export function personNameRegex() {
    return '^[a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*$'
}

export function setLanguage(language) {
    i18n.changeLanguage(language);
}

export function getLanguage() {
    const lang = localStorage.getItem('i18nextLng');
    if (lang) {
        return lang;
    }

    return false;
}