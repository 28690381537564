import React from "react";
import { connect } from "react-redux";
import { Content, Box } from "../../components/Elements";
import { ButtonToolbar } from "react-bootstrap";
import * as PrescriptionAction from "../../actions/prescriptionActions";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import * as HELPER from "../../utils/helpers";
import DataTable from '../../components/DataTable';
import { capitalize } from 'lodash'
import { withTranslation } from 'react-i18next';

class ListPrescriptionPage extends React.Component {
    constructor(props) {
        super(props);

        this.props.paginate();
    }

    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: 'prescription_id',
                text: t('labels.prescription-id'),
                sort: true
            }, {
                dataField: 'doctor_service_provider_id',
                text: t('labels.doctor-id'),
                sort: false
            }, {
                dataField: 'doctor_full_name',
                text: t('labels.doctor-name'),
                sort: false
            }, {
                dataField: 'prescription_date',
                text: t('labels.date'),
                sort: true,
                formatter: this.dateFormatter
            }, {
                dataField: 'medicine_status',
                text: t('labels.medication-status'),
                formatter: this.statusFormatter
            }, {
                dataField: 'pathology_test_status',
                text: t('labels.pathology-test-status'),
                formatter: this.statusFormatter
            }, {
                dataField: 'radiology_test_status',
                text: t('labels.radiology-test-status'),
                formatter: this.statusFormatter
            }, {
                dataField: 'actions',
                text: t('labels.actions'),
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    statusFormatter = (cell, row) => {
        if (!cell) {
            return 'NA';
        }
        return capitalize(cell)
    }

    dateFormatter = (cell, row) => {
        return HELPER.getDateFormat(cell);
    }

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['prescriptions-view']) && <Link className="btn btn-sm btn-info" to={'/prescriptions/' + row.prescription_id}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
        </ButtonToolbar>;
    }

    handleTableChange = (_type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        this.props.paginate(page, sizePerPage, { sortField, sortOrder }, searchText);
    }


    render() {

        let { data, meta, loading } = this.props.prescription_list;
        let { t } = this.props;

        return <div>
            <Helmet>
                <title>{t('labels.list-prescriptions')}</title>
            </Helmet>
            <Content title={t('labels.prescriptions')} >
                <Box>
                    <Box.Head title={t('labels.list-prescriptions')} />
                    <Box.Body>
                        <DataTable
                            key="list_prescription"
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}
function mapStateToProps(state) {
    return {
        prescription_list: state.prescription_list,
        admin: state.auth.admin
    };
};

const mapActionsToProps = ({
    paginate: PrescriptionAction.list
});

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/prescription/ListPrescriptionPage')(ListPrescriptionPage));
