import * as types from './actionTypes';
import AuthService from '../services/authService';
import * as authUtils from '../utils/auth';
import * as HELPERS from './../utils/helpers';

export function login(username, password, history) {

    return dispatch => {
        dispatch({ type: types.LOGIN_REQUEST });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        AuthService.login(username, password)
            .then(resp => {

                authUtils.setSessionToken(resp.data);
                authUtils.setUser(resp.data.admin);
                HELPERS.setLanguage(resp.data.admin.language);

                dispatch({ type: types.RESET_ALL });
                dispatch({ type: types.LOGIN_SUCCESS, ...resp.data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Welcome !' });

                history.push('/dashboard');
            })
            .catch(error => {
                dispatch({ type: types.LOGIN_FAILED });
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            }
            );
    };
}

export function logout(history) {
    return dispatch => {
        AuthService.logout()
            .then(
                data => {
                    authUtils.removeUser()
                    authUtils.removeSessionToken()

                    dispatch({ type: types.RESET_ALL });
                    dispatch({ type: types.LOGOUT });
                    dispatch({ type: types.ALERT_SUCCESS, message: 'Logout Successfully' });
                    history.push('/login');
                }
            );
    }
}

export function forgotPassword(username, history) {

    return dispatch => {
        dispatch({ type: types.FORGOT_PASSWORD_REQUEST });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        AuthService.forgotPassword(username).then(response => {
            dispatch({ type: types.FORGOT_PASSWORD_SUCCESS });
            dispatch({ type: types.ALERT_SUCCESS, message: response.data.message });

            history.push('/login');
        }).catch(error => {
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                dispatch({ type: types.ALERT_ERROR, ...errorData });
            } else {
                dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
            }
        });

    }

}

export function displaycustomemessage(alert_type, message) {
    if (message && alert_type) {
        let type_alert = false;

        if (alert_type === 'success') {
            type_alert = types.ALERT_SUCCESS;
        } else if (alert_type === 'failed') {
            type_alert = types.ALERT_ERROR;
        } else {
            type_alert = types.ALERT_CLEAR;
        }

        return dispatch => {
            dispatch({ type: type_alert, message: message });
        }
    }
}

export function resetPassword(username, token, password, confirm_password, history) {
    return dispatch => {
        dispatch({ type: types.RESET_PASSWORD_REQUEST });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        AuthService.resetPassword(username, token, password, confirm_password).then(response => {
            dispatch({ type: types.RESET_PASSWORD_SUCCESS });
            dispatch({ type: types.ALERT_SUCCESS, message: 'Password Reset Successfully' });
            history.push('/login');
        }).catch(error => {
            dispatch({ type: types.RESET_PASSWORD_FAILURE });
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                dispatch({ type: types.ALERT_ERROR, ...errorData });
            } else {
                dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
            }
        });
    }
}

export function newPassword(username, token, password, confirm_password, history) {
    return dispatch => {
        dispatch({ type: types.RESET_PASSWORD_REQUEST });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        AuthService.newPassword(username, token, password, confirm_password).then(response => {

            dispatch({ type: types.RESET_PASSWORD_SUCCESS });
            dispatch({ type: types.ALERT_SUCCESS, message: 'New Password Updated Successfully' });

            history.push('/login');
        }).catch(error => {
            dispatch({ type: types.RESET_PASSWORD_FAILURE });

            if (error.response && error.response.status) {
                let errorData = error.response.data;
                dispatch({ type: types.ALERT_ERROR, ...errorData });
            } else {
                dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
            }
        });
    }
}

export function navaction(parentnav, childnav) {
    return dispatch => {
        dispatch({ type: types.UPDATE_NAV, parent: parentnav, child: childnav });
    }

}
