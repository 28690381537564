import React from 'react';
import { Content, Box } from '../../components/Elements';
import { Helmet } from 'react-helmet';
import { FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap';
import * as loaderActions from '../../actions/loaderAction';
import * as AdminUserAction from '../../actions/adminuserActions';
import { connect } from 'react-redux';
import * as authUtils from '../../utils/auth';
import * as HELPER from '../../utils/helpers';
import SimpleReactValidator from 'simple-react-validator';
import { withTranslation } from 'react-i18next';

class ChangeLanguagePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { language: '' }
        this.validator = new SimpleReactValidator({ locale: HELPER.getLanguage() });
        const id = authUtils.getUser().admin_id;
        this.props.fetchadminuser(id);
    }

    componentDidUpdate(prevProps) {
        if(this.props !== prevProps){
            this.setAdminUser(this.props.admin_user)
        }
    }

    setAdminUser = (data) => {
        this.setState({
            id: data.admin_id ? data.admin_id : '',
            admin_id: data.admin_id ? data.admin_id : '',
            first_name: data.first_name ? data.first_name : '',
            last_name: data.last_name ? data.last_name : '',
            email: data.email ? data.email : '',
            contact_number: data.contact_number ? data.contact_number : '',
            trn_number: data.trn_number ? data.trn_number : '',
            gender: data.gender ? data.gender : '',
            name_title: data.name_title ? data.name_title : '',
            date_of_birth: data.date_of_birth ? data.date_of_birth : '',
            flag: false,
            assigned_role: data.roles ? data.roles.role_id : '',
            currentStatus: data.status === 1 ? 'active' : 'inactive',
            status: data.status === 1 ? 'active' : 'inactive',
            language: data.language ? data.language : ''
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.adminuserupdate(this.state, this.props.history, this.state.language);
    }
    render() {
        let { t } = this.props;
        return <React.Fragment>
            <Helmet>
                <title>{t('labels.change-language')}</title>
            </Helmet>
            <Content title={t('labels.change-language')}>
                <Box>
                    <Box.Body>
                        <form onSubmit={this.handleSubmit}>
                            <FormGroup className="row" controlId="">
                                <Col sm={2}>
                                    <ControlLabel>{t('labels.select-language')}: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='language'
                                        type="text"
                                        onChange={this.handleChange}
                                        componentClass="select"
                                        value={this.state.language}
                                    >
                                        <option value="en">English</option>
                                        <option value="es">Spanish</option>
                                    </FormControl>
                                    {this.validator.message('language', this.state.language, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={6} md={6}>
                                    <button type='submit' className='btn btn-primary'>{t('labels.submit')}</button>
                                </Col>
                            </FormGroup>
                        </form>
                    </Box.Body>
                </Box>
            </Content>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => ({
    admin_user: state.admin_user
});

const mapActionsToProps = ({
    loaderShow: loaderActions.loaderShow,
    loaderHide: loaderActions.loaderHide,
    fetchadminuser: AdminUserAction.fetchadminuser,
    adminuserupdate: AdminUserAction.adminuserupdate
})
export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/others/ChangeLanguagePage')(ChangeLanguagePage))

